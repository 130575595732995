import React from 'react'

import { View } from 'src/components/shared'
import { ActiveFilters } from 'src/components/shared/Filter/ActiveFilters'

import { Filters } from './Filters'

export function ListView() {
  return (
    <View
      FiltersComponent={<Filters />}
      ActiveFiltersComponent={<ActiveFilters />}
    />
  )
}
