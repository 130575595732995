import { QueryClient } from 'react-query'
import { LoaderFunctionArgs } from 'react-router-dom'
import urlJoin from 'url-join'
import WPAPI from 'wpapi'

import { i18n } from '../../i18n'
import { WordpressPage, wordpressEndpoint } from '../../wordpress'

export interface CMSLoaderDataProps {
  page: WordpressPage
  error: string
}

function fetchWordpressPageWithSlugs(
  wordpressEndpoint: string,
  slug: string,
  lang: string,
): Promise<WordpressPage[]> {
  const wp = new WPAPI({
    endpoint: urlJoin(wordpressEndpoint, 'wp-json'),
  })

  // get page according to i18n lang and slug
  return wp.pages().slug(slug).param('lang', lang).get()
}

export async function fetchCms(
  slug: string,
  lang: string,
  queryClient: QueryClient,
) {
  const queryKeyCmsPage = ['cms', slug, lang]
  const queryFnCmsPage = () =>
    fetchWordpressPageWithSlugs(wordpressEndpoint, slug, lang)

  return (
    queryClient.getQueryData(queryKeyCmsPage) ??
    (await queryClient.fetchQuery(queryKeyCmsPage, queryFnCmsPage))
  )
}

export const cmsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { slug } = params
    const lang = i18n.language
    if (!slug) {
      throw new Response('Slug is missing', { status: 400 })
    }

    try {
      const page = await fetchCms(slug, lang, queryClient)
      return { page }
    } catch (error) {
      return { page: undefined, error }
    }
  }
