import clsx from 'clsx'
import React from 'react'
import { Link, useLoaderData, useLocation } from 'react-router-dom'

import { useMediaQuery } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import Pagination, {
  PaginationRenderItemParams,
} from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'

import { useSearchTranslation } from 'src/components/search/utils'
import { DiscoveryLoaderDataProps } from 'src/contexts/loader'
import { toSearchParams, useParsedSearchParams } from 'src/utils/searchparams'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  itemTypo: theme.typography.caption,
}))

interface Props {
  className?: string
  ariaLabel?: string
}

export function Paging(props: Readonly<Props>) {
  const { className } = props
  const { searchResult } = useLoaderData() as DiscoveryLoaderDataProps

  const theme = useTheme()
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  })
  const params = useParsedSearchParams()
  const parsedLocation = useLocation()

  const cls = useStyles()
  const { searchT, searchTT } = useSearchTranslation()

  const { numFound, start, rows } = searchResult.metadata

  if (numFound < 1 || rows < 1) {
    return null
  }

  const pageLength = Math.ceil(numFound / rows)
  const pageIndex = Math.floor(start / rows) // zero-based

  const renderItem = (item: PaginationRenderItemParams) => {
    const disabled = item.page === 0 || item.page > pageLength

    let ariaLabel
    if (item.type === 'previous') {
      ariaLabel = searchT('paging', 'goToPreviousPage')
    } else if (item.type === 'next') {
      ariaLabel = searchT('paging', 'goToNextPage')
    } else if (item.type === 'page') {
      ariaLabel = searchTT(
        { pageNumber: item.page.toString() },
        'paging',
        'goToPage',
      )
    } else {
      ariaLabel = ''
    }

    if (disabled) {
      return (
        <PaginationItem
          {...item}
          className={cls.itemTypo}
          component={'span'}
          disabled={true}
        />
      )
    }
    const to = `${parsedLocation.pathname}?${toSearchParams({
      ...params,
      start: (item.page - 1) * rows,
    })}`

    return (
      <PaginationItem
        {...(item as any)}
        className={clsx(cls.itemTypo)}
        component={Link}
        to={to}
        disabled={false}
        aria-label={ariaLabel}
      />
    )
  }

  return (
    <Pagination
      aria-label={props.ariaLabel ?? 'Pagination navigation'}
      className={clsx(className, cls.root)}
      count={pageLength}
      page={pageIndex + 1}
      renderItem={renderItem}
      siblingCount={0}
      size={smallerThanMd ? 'small' : 'medium'}
    />
  )
}
