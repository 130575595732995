import React from 'react'

import { NormOverview } from 'src/components/authorityFiles'
import { useSetupModulesForPage } from 'src/contexts/modules'
import { useTrackPageView } from 'src/contexts/tracking'

export function AuthorityFile() {
  useSetupModulesForPage()
  useTrackPageView('AuthorityFile')

  return <NormOverview />
}
