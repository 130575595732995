import React from 'react'

import MuiGrid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { ActiveFilters } from 'src/components/shared/Filter/ActiveFilters'
import { Grid } from 'src/components/shared/Grid'

import { orderCatalogFacets } from '../config'
import { CatalogAccordion } from './CatalogAccordion'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.liver.light,
  },
  facetContainer: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  activeFilters: {
    paddingBottom: theme.spacing(1),
  },
}))

export const CatalogFilters: React.FC = () => {
  const cls = useStyles()

  return (
    <div className={cls.root}>
      <Grid id="catalogFilters">
        <MuiGrid container className={cls.facetContainer} spacing={2}>
          {orderCatalogFacets.map((facet) => (
            <MuiGrid item xs={12} sm={3} key={facet}>
              <CatalogAccordion filterName={facet} />
            </MuiGrid>
          ))}
        </MuiGrid>
      </Grid>
      <Grid>
        <div className={cls.activeFilters}>
          <ActiveFilters />
        </div>
      </Grid>
    </div>
  )
}
