import clsx from 'clsx'
import React, { ReactNode, useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { ErrorPage } from 'src/components/shared/ErrorPage'
import { Grid } from 'src/components/shared/Grid'
import { DiscoveryLoaderDataProps } from 'src/contexts/loader'
import { useGetLocationType } from 'src/utils/useGetLocationType'

import { useSearchTranslation } from '../../search/utils'
import { ActiveFilters } from '../Filter/ActiveFilters'
import { Tools } from '../Tools'
import { Paging } from '../Tools/Paging'
import { Hits } from './ResultHits'

const TIMEOUT_DURATION = 10000

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  marginTop: {
    marginTop: theme.spacing(10),
  },
  activeFilters: {
    marginTop: theme.spacing(3),
  },
  tools: {
    marginTop: theme.spacing(4),
  },
  hits: {
    marginTop: theme.spacing(3),
  },
  bottomPaging: {
    margin: 'auto',
    width: '50%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  catalog: {
    backgroundColor: theme.palette.action.selected,
  },
}))

interface ViewProps {
  FiltersComponent?: ReactNode
  ActiveFiltersComponent?: ReactNode
}

function handleSearchTimeout(
  searchResult: unknown,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (typeof searchResult === 'undefined') {
    const timeoutId = setTimeout(() => {
      setShowError(true)
    }, TIMEOUT_DURATION)
    return () => clearTimeout(timeoutId)
  }
}

function renderErrorPage(locationType: string, errorMessage: string) {
  return (
    <ErrorPage
      id={`discovery-${locationType}-view`}
      errorMessage={errorMessage}
    />
  )
}

export function View({ FiltersComponent }: Readonly<ViewProps>) {
  const cls = useStyles()
  const [showError, setShowError] = useState(false)

  const { searchT } = useSearchTranslation()
  const { searchResult } = useLoaderData() as DiscoveryLoaderDataProps
  const locationType = useGetLocationType()

  useEffect(
    () => handleSearchTimeout(searchResult, setShowError),
    [searchResult],
  )

  if (showError) {
    return renderErrorPage(locationType, searchT('searchFailed'))
  }

  return (
    <div
      className={
        locationType === 'catalogs' ? clsx(cls.root, cls.catalog) : cls.root
      }
    >
      {FiltersComponent}
      <Grid id="searchGrid">
        <div className={cls.marginTop}>
          {locationType === 'objects' && (
            <div className={cls.activeFilters}>
              <ActiveFilters />
            </div>
          )}
          <Tools className={cls.tools} />
        </div>
        <Hits className={cls.hits} />
        <Paging
          ariaLabel={'Pagination navigation bottom'}
          className={cls.bottomPaging}
        />
      </Grid>
    </div>
  )
}
