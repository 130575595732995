import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import { actions } from 'src/contexts/actions/actions'
import { useTranslation } from 'src/contexts/i18n'
import { useDispatch } from 'src/contexts/state'

import { GlobalTools } from '../shared/GlobalTools'
import { Menu } from '../shared/Menu'
import { SideBarButton } from '../shared/SideBarButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    height: 'inherit',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  misc: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

interface Props {
  className?: string
  mobile: boolean
}

export function SideBar(props: Readonly<Props>) {
  const dispatch = useDispatch()
  const cls = useStyles()
  const { t } = useTranslation()

  return (
    <nav
      className={clsx(cls.root, props.className)}
      aria-label={t('sidebar.sidebar')}
    >
      <div>
        {props.mobile && (
          <div className={cls.header}>
            <Link
              to="/"
              aria-label={t('topBar.logoLink')}
              onClick={() => dispatch(actions.toggleSidebar())}
            >
              <img
                src="/img/handschriftenportal_logo.svg"
                width="200"
                alt={t('logo')}
              />
            </Link>
            <SideBarButton />
          </div>
        )}
        <Menu mobile={props.mobile} />
      </div>
      <div>
        <GlobalTools mobile={props.mobile} />
        <Divider />
      </div>
    </nav>
  )
}
