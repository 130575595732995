import urlJoin from 'proper-url-join'
import { QueryClient } from 'react-query'

import { AuthItemProps, discoveryEndpoint, fetchJson } from '../discovery/'
import { fetchHspObjectsQuery } from './searchLoader'

export interface AuthorityFileLoaderDataProps {
  authItem: AuthItemProps
  numFound: number
  error: Error
}

async function fetchNormObjectQuery(
  id: string | null,
  queryClient: QueryClient,
) {
  const normEndpoint = urlJoin(discoveryEndpoint, '/authority-files/', id ?? '')

  const queryKeyNorm = ['authorityFileById', id]
  const queryFnNorm = () => fetchJson(fetch, normEndpoint)

  return (
    queryClient.getQueryData(queryKeyNorm) ??
    (await queryClient.fetchQuery(queryKeyNorm, queryFnNorm))
  )
}

/**
 * Fetches a authItem and the numFound for it by its id.
 */
export const normLoader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const authorityfileid = new URL(request.url).searchParams.get(
      'authorityfileid',
    )

    const params = {
      q: `${authorityfileid}`,
      fq: undefined,
      start: undefined,
      rows: undefined,
    }

    const hspObjects = await fetchHspObjectsQuery(params, queryClient)
    const numFound = hspObjects.metadata.numFound ?? 0
    const authItem = await fetchNormObjectQuery(authorityfileid, queryClient)

    return { authItem, numFound }
  }
